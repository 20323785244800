import React from 'react';
import styled from 'styled-components';

import pageBg from './top section (16_9).png';

const PageTitle = styled.div`
  position: relative;
  min-height: 200px;
  max-height: 200px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  h1 {
    margin: 30px 0;
    font-size: 2.5rem;
  }
  p {
    font-size: 14px;
  }
  img {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: -1;
    object-fit: cover;
  }
`;

export default function WeFixItPageTitle({ pageName }) {
  return (
    <PageTitle>
      <img src={pageBg} alt="page background" />
      <h1><strong>{pageName}</strong></h1>
      <p>Home / {pageName}</p>
    </PageTitle>
  )
}

import React, { useState, useEffect } from 'react';
import Http from '../Http';
import styled from 'styled-components';
import WeFixItButton from './WeFixItButton';

const DropOffWrapper = styled.div`
  position: fixed;
  z-index: ${ props => props.visible ? 1000 : -1000 };
  display: ${ props => props.visible? '' : 'none' };
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: #ffffff7a;
  backdrop-filter: blur(25px);
  display: flex;
  align-items: center;
  overflow-y: auto;
  justify-content: center;
`;

const DropOffForm = styled.div`
  position: relative;
  top: 100px;

  margin-bottom: 50px;
  background: white;
  z-index: ${ props => props.visible ? 1000 : -1000 };
  display: ${ props => props.visible ? 'block' : 'none' };
  border-radius: 15px;
  width: 70%;
  box-shadow: -2px 3px 37px -10px rgba(0,0,0,0.14);
  padding: 45px;
  @media (max-width: 550px) {
    top: 392px;
    width: 90%;
    margin-bottom: 355px;
  }
`;

const DropOffSuccess = styled.div`
  position: relative;
  background: white;
  z-index: ${ props => props.visible ? 1000 : -1000 };
  display: ${ props => props.visible ? 'flex' : 'none' };
  border-radius: 15px;
  width: 70%;
  box-shadow: -2px 3px 37px -10px rgba(0,0,0,0.14);
  padding: 45px;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  & > .thank-you {
    margin-bottom: 30px;
    font-weight: 700;
  }

  & > .status-tracking-info{
    margin-bottom: 20px;
    font-weight: 700;
  }

  & > .status-code{
    margin-bottom: 30px;
    font-weight: 700;
  }

  & > .whats-next{
    margin-bottom: 15px;
    font-weight: 700;
  }

  & > .next-step{
    margin-bottom: 5px;
    font-weight: 700;

  }

  & > .address{
    margin-bottom: 20px;
    font-weight: 700;
  }
`;

export const FormGroup = styled.div`
  display: flex;
  flex-direction: column;
  margin: 20px 0;
  & > label {
    font-weight: 600;
    margin-bottom: 10px;
  }
  & > input, & > select,
  & > textarea  {
    padding: 10px 0;
    border: none;
    border-bottom: 2px solid rgb(226, 226, 226);
    &.error {
      border-color: #ff0000ae;
    }
  }
  & > select option {
    padding: 10px 0;
  }

  & > textarea {
    resize: none;
  }

  & > .alert-danger {
    background: #ff0000ae;
    color: white;
    padding: 10px;
    border-radius: 5px;
    font-size: 12px;
  }


`;

const DropOffFormRow = styled.div`
  display: flex;
  justify-content: space-between;
  @media (max-width: 550px) {
   flex-direction: column;
  }
  & > div {
    width: 48%;
    @media (max-width: 550px) {
      width: 98%;
      margin-bottom: 10px;

    }
  }
`;

const SpinnerContainer = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  z-index: 10;
  align-items: center;
  justify-content: center;
  background-color: #ffffffae;
  backdrop-filter: blur(10px);
  & > .lds-heart {
    display: inline-block;
    position: relative;
    width: 80px;
    height: 80px;
    transform: rotate(45deg);
    transform-origin: 40px 40px;
  }
  & > .lds-heart div {
    top: 32px;
    left: 32px;
    position: absolute;
    width: 32px;
    height: 32px;
    background: var(--primary-color);
    animation: lds-heart 1.2s infinite cubic-bezier(0.215, 0.61, 0.355, 1);
  }
  & > .lds-heart div:after,
  & > .lds-heart div:before {
    content: " ";
    position: absolute;
    display: block;
    width: 32px;
    height: 32px;
    background: var(--primary-color);
  }
  & > .lds-heart div:before {
    left: -24px;
    border-radius: 50% 0 0 50%;
  }
  & > .lds-heart div:after {
    top: -24px;
    border-radius: 50% 50% 0 0;
  }
  @keyframes lds-heart {
    0% {
      transform: scale(0.95);
    }
    5% {
      transform: scale(1.1);
    }
    39% {
      transform: scale(0.85);
    }
    45% {
      transform: scale(1);
    }
    60% {
      transform: scale(0.95);
    }
    100% {
      transform: scale(0.9);
    }
  }

`;

const CustomFileInputContainer = styled.div`
  display: flex;
  justify-content: space-between;
  width: 48%;
  @media (max-width: 550px) {
    width: 98%;

  }
`;


const CustomFileInput = styled.label`
  width: 100px;
  height: 100px;
  @media (max-width: 550px) {
    width: 50px;
    height: 50px;

  }
  color: black;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 2rem;
  opacity: .7;
  cursor: pointer;
  border: 1px solid #ccc;
  &:hover{
    opacity: 1;
    border-color: black;
  }
  & > input[type="file"] {
    display: none;
  }
`;

const DropOffFormCloseButton = styled.div`
  position: absolute;
  right: 20px;
  top: 20px;
  cursor: pointer;
  padding: 10px;
  border-radius: 5px;
  &:hover{
    background: #e4e4e4;
  }
`;

export default function DropOff({isVisible, handleClose}) {
  document.body.style.overflowY = isVisible ? 'hidden' : 'auto';

  const [userName, setUserName] = useState('');
  const [phone, setPhone] = useState('');
  const [email, setEmail] = useState('');
  const [serialNumber, setSerialNumber] = useState('');
  const [repairTypeId, setRepairTypeId] = useState('');
  const [description, setDescription] = useState('');
  const [firstImage, setFirstImage] = useState('');
  const [secondImage, setSecondImage] = useState('');
  const [thirdImage, setThirdImage] = useState('');

  const [repairTypes, setRepairTypes] = useState([]);
  const [showForm, setShowForm] = useState(true);
  const [showSuccess, setShowSuccess] = useState(false);
  const [showSpinner, setShowSpinner] = useState(false);

  const [usernameError, setUsernameError] = useState('');
  const [phoneError, setPhoneError] = useState('');
  const [emailError, setEmailError] = useState('');
  const [serialNumberError, setSerialNumberError] = useState('');
  const [descriptionError, setDescriptionError] = useState('');

  const [statusCode, setStatusCode] = useState('');

  useEffect(() => {
    Http.get('/api/get-repair-types').then(resp => setRepairTypes(resp.data));
  }, []);

  useEffect(() => {
    if(repairTypes.length > 0) setRepairTypeId(repairTypes[0].id)
  }, [repairTypes]);

  const isEmail = (mail) =>
  {
   if (/^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/.test(mail))
    {
    return true;
    }
    return false;
  }

  const formIsValid = () => {
    setUsernameError('');
    setPhoneError('');
    setEmailError('');
    setSerialNumberError('');
    setDescriptionError('');

    if(userName === '') {
      setUsernameError('* Full Name is required.');
      return false;
    }
    if(phone === '') {
      setPhoneError('* Contact number is required.');
      return false;
    }
    if(email === '') {
      setEmailError('* Email is required.');
      return false;
    }
    if(!isEmail(email)) {
      setEmailError('* You have entered an invalid email address!');
      return false;
    }
    if(serialNumber === '') {
      setSerialNumberError('* Serial Number is required.');
      return false;
    }
    if(description === '') {
      setDescriptionError('* Description is required.');
      return false;
    }
    return true;
  }

  const submitForm = () => {
    if(formIsValid()){
      setShowSpinner(true);

      Http.post('/api/tickets', {
        username: userName,
        phone,
        email,
        description,
        serial_number: serialNumber,
        status: 1,
        repair_type_id: repairTypeId,
        images: [firstImage, secondImage, thirdImage],
      }).then(resp => {
        if(resp.status===201) {
          setStatusCode('#'+resp.data.ticket_id)
          setShowSuccess(true);
          setShowSpinner(false);
          setShowForm(false);
        }
      }).catch(error => {
        console.log(error);
      });
    }
  };

  const getLocation = () => {
    setShowSuccess(false);
    window.location.hash = "";
    handleClose();
    setTimeout(()=> {
      window.location.hash = "map";
      setShowForm(true);
    }, 300)
  }

  return (
    <DropOffWrapper visible={isVisible}>
    {
      !showForm ?
        <DropOffSuccess visible={isVisible && showSuccess}>
          <h3 className="thank-you">Thank you for using our online drop off feature.</h3>
          <h5 className="status-tracking-info">Here is your Device Status tracking number.</h5>
          <h4 className="status-code">{statusCode}</h4>
          <h2 className="whats-next">What's Next?</h2>
          <h5 className="next-step">Drop your device at Wefixit Nepal.</h5>
          <h5 className="address">Newroad, Kathmandu</h5>
          <WeFixItButton onClick={getLocation}>Get Location</WeFixItButton>
        </DropOffSuccess>
      :
      <DropOffForm visible={isVisible && showForm} >
        {showSpinner ?
          <SpinnerContainer>
            <div class="lds-heart"><div></div></div>
          </SpinnerContainer> : ''
        }
        <DropOffFormCloseButton onClick={handleClose}>
          X
        </DropOffFormCloseButton>
        <h1>Drop Off Form</h1>
        <DropOffFormRow>
          <FormGroup>
            <label>Full Name</label>
            {usernameError !== '' ? <span className="alert-danger">{usernameError}</span> : ''}
            <input className={usernameError !== '' ? 'error' : ''} type="text" value={userName} onChange={e=>setUserName(e.target.value)} placeholder="Ram Nepal" />
          </FormGroup>
          <FormGroup>
            <label>Your Phone Number</label>
            {phoneError !== '' ? <span className="alert-danger">{phoneError}</span> : ''}
            <input className={phoneError !== '' ? 'error' : ''} type="tel" value={phone} onChange={e=>setPhone(e.target.value)} placeholder="98xxxxxxxx" />
          </FormGroup>
        </DropOffFormRow>
        <DropOffFormRow>
          <FormGroup>
            <label>Your Email Address</label>
            {emailError !== '' ? <span className="alert-danger">{emailError}</span> : ''}
            <input className={emailError !== '' ? 'error' : ''} type="email" value={email} onChange={e=>setEmail(e.target.value)} placeholder="ram.nepal@gmail.com" />
          </FormGroup>
          <FormGroup>
            <label>Serial Number</label>
            {serialNumberError !== '' ? <span className="alert-danger">{serialNumberError}</span> : ''}
            <input className={serialNumberError !== '' ? 'error' : ''} type="text" value={serialNumber} onChange={e=>setSerialNumber(e.target.value)} placeholder="Please provide serial number of your device." />
          </FormGroup>
        </DropOffFormRow>
        <DropOffFormRow style={{justifyContent: 'center'}}>
          <FormGroup>
            <label>Please Select Your Device and Problem</label>
            <select value={repairTypeId} onChange={e=>setRepairTypeId(e.target.value)} >
              {repairTypes.map(type =>
                <option key={type.id} value={type.id}>{type.display_name}</option>
              )}
            </select>
          </FormGroup>
        </DropOffFormRow>
        <DropOffFormRow>
          <FormGroup style={{width: '100%'}}>
            <label htmlFor="vehicle1"> Tell us a bit about your problem.</label><br />
            {descriptionError !== '' ? <span className="alert-danger">{descriptionError}</span> : ''}
            <textarea className={descriptionError !== '' ? 'error' : ''} onChange={e=>setDescription(e.target.value)} value={description} />
          </FormGroup>
        </DropOffFormRow>
        <DropOffFormRow>
          <FormGroup style={{width: '100%'}}>
            <label htmlFor="vehicle1"> Upload photo of your device that shows your problem.</label><br />
            <CustomFileInputContainer>
              <CustomFileInput id="image-uploader-1" onChange={ e => {
                var reader = new FileReader();
                reader.onload = function(event){
                    var img = new Image();
                    setFirstImage(event.target.result);
                    var canvas = document.createElement("canvas");
                    canvas.style.position= 'absolute';
                    canvas.addEventListener('click', e => {
                      document.querySelectorAll('#image-uploader-1 span')[0].style.opacity = '1';
                      e.currentTarget.remove();
                      setFirstImage('');
                      setTimeout(_=> {
                        document.querySelectorAll('#image-uploader-1 input')[0].removeAttribute('disabled');
                      }, 100)
                    })
                    var ctx = canvas.getContext("2d");
                    document.querySelectorAll('#image-uploader-1')[0].appendChild(canvas);
                    document.querySelectorAll('#image-uploader-1 input')[0].setAttribute('disabled', true);
                    document.querySelectorAll('#image-uploader-1 span')[0].style.opacity = '0';
                  img.onload = function () {
                      canvas.width = 100;
                      canvas.height = 100;

                      let sourceX = 0;
                      let sourceY = 0;
                      let sourceWidth = img.width - sourceX; // you're in the bounds
                      let sourceHeight = img.height;
                      let destX = 0;
                      let destY = 0;

                      let destWidth = canvas.width;
                      let destHeight = canvas.height;

                      ctx.drawImage(img, sourceX, sourceY, sourceWidth, sourceHeight, destX, destY, destWidth, destHeight);
                    }
                    img.src = event.target.result;
                }
                reader.readAsDataURL(e.target.files[0]);
              }}>
                <span>+</span>
                <input type="file" />
              </CustomFileInput>
              <CustomFileInput id="image-uploader-2" onChange={ e => {
                var reader = new FileReader();
                reader.onload = function(event){
                    var img = new Image();
                    setSecondImage(event.target.result);
                    var canvas = document.createElement("canvas");
                    var ctx = canvas.getContext("2d");

                    canvas.style.position= 'absolute';
                    canvas.addEventListener('click', e => {
                      document.querySelectorAll('#image-uploader-2 span')[0].style.opacity = '1';
                      e.currentTarget.remove();
                      setTimeout(_=> {
                        document.querySelectorAll('#image-uploader-2 input')[0].removeAttribute('disabled');
                      }, 100)
                      setSecondImage('');
                    })

                    document.querySelectorAll('#image-uploader-2')[0].appendChild(canvas);
                    document.querySelectorAll('#image-uploader-2 input')[0].setAttribute('disabled', true);
                    document.querySelectorAll('#image-uploader-2 span')[0].style.opacity = '0';

                    img.onload = function(){
                        canvas.width = 100;
                        canvas.height = 100;
                        ctx.drawImage(img,0,0, 100, 100);
                    }
                    img.src = event.target.result;
                }
                reader.readAsDataURL(e.target.files[0]);
              }}>
                <span>+</span>
                <input type="file" />
              </CustomFileInput>
              <CustomFileInput id="image-uploader-3" onChange={ e => {
                var reader = new FileReader();
                reader.onload = function(event){
                    var img = new Image();
                    setThirdImage(event.target.result);
                    var canvas = document.createElement("canvas");
                    canvas.style.position= 'absolute';
                    canvas.addEventListener('click', e => {
                      document.querySelectorAll('#image-uploader-3 span')[0].style.opacity = '1';
                      e.currentTarget.remove();
                      setThirdImage('');
                      setTimeout(_=> {
                        document.querySelectorAll('#image-uploader-3 input')[0].removeAttribute('disabled');
                      }, 100)
                    })
                    var ctx = canvas.getContext("2d");
                    document.querySelectorAll('#image-uploader-3')[0].appendChild(canvas);
                    document.querySelectorAll('#image-uploader-3 input')[0].setAttribute('disabled', true);
                    document.querySelectorAll('#image-uploader-3 span')[0].style.opacity = '0';
                    img.onload = function(){
                        canvas.width = 100;
                        canvas.height = 100;
                        ctx.drawImage(img,0,0, 100, 100);
                    }
                    img.src = event.target.result;
                }
                reader.readAsDataURL(e.target.files[0]);
              }}>
                <span>+</span>
                <input type="file" />
              </CustomFileInput>
            </CustomFileInputContainer>
          </FormGroup>
        </DropOffFormRow>

        <DropOffFormRow>
          <WeFixItButton style={{marginBottom: 10}} onClick={submitForm}>Drop Off</WeFixItButton>
          <WeFixItButton onClick={handleClose}>Cancel</WeFixItButton>
        </DropOffFormRow>

      </DropOffForm>
    }
    </DropOffWrapper>
  )
}

import React, { useEffect, useState } from 'react';
import { useParams } from "react-router-dom";
import styled from 'styled-components';
import WeFixItButton from '../../Components/WeFixItButton';
import WeFixItLoadingState from '../../Components/WeFixItLoadingState';
import WeFixItNotFound from '../../Components/WeFixItNotFound';
import WeFixItPageTitle from '../../Components/WeFixItPageTitle';
import Http from '../../Http';
import ErrorPanel from '../../Components/ErrorPanel';
import { Container } from '../HomePage/wrapper';

const SearchPageContainer = styled.div`
  display: flex;
  align-items: center;
  min-height: 100vh;
  justify-content: flex-start;
  flex-direction: column;
  & > h1 {
    margin-top: 50px;
    margin-bottom: 30px;
    text-align: center;
  }
`;

const SearchResult = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: space-around;
  width: 70%;
  min-height: 500px;
  background: linear-gradient(90deg,rgb(125 105 217 / 26%) 0%,rgb(195 126 222 / 42%) 100%);
  padding: 20px;
  margin-bottom: 20px;
  position: relative;

  & > .discussion {
    max-width: 600px;
    margin: 0 auto;

    display: flex;
    flex-flow: column wrap;
    & > .bubble {
      border-radius: 1em;
      padding: 0.25em 0.75em;
      margin: 0.0625em;
      max-width: 50%;
    }
    & > .bubble.sender {
      align-self: flex-start;
      background-color: cornflowerblue;
      color: #fff;
    }
    & > .bubble.recipient {
      align-self: flex-end;
      background-color: #efefef;
    }

    & > .bubble.sender.first { border-bottom-left-radius: 0.1em; }

    & > .bubble.sender.last { border-top-left-radius: 0.1em; }

    & > .bubble.sender.middle {
      border-bottom-left-radius: 0.1em;
      border-top-left-radius: 0.1em;
    }

    & > .bubble.recipient.first { border-bottom-right-radius: 0.1em; }

    & > .bubble.recipient.last { border-top-right-radius: 0.1em; }

    & > .bubble.recipient.middle {
      border-bottom-right-radius: 0.1em;
      border-top-right-radius: 0.1em;
    }
  }

`;

export default function SearchPage(props) {
  const { query, type } = useParams();
  const [assetPath, setAssetPath] = useState([])
  const [apiPath, setApiPath] = useState([])
  const [tickets, setTickets] = useState([])
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);

  useEffect(()=> {
    if(query) {
      Http.get(`api/search/${query}/${type}`).then(resp => {
        setTickets(resp.data.tickets);
        Http.get('api/get-asset-path').then(resp => {
          setAssetPath(resp.data.asset_path)
          setLoading(false);
          setApiPath(resp.data.asset_path.replace('storage', ''));
        }).catch(err => {
          setError(err)
          setLoading(false);
        });
      }).catch(err => {
        setError(err)
        setLoading(false);
      });
    }
  }, [query, type]);

  const getAssetPath = (path) => assetPath + '/' + path ;

  return (
    <SearchPageContainer>
      <WeFixItPageTitle pageName="Search" />

      {Boolean(error) && <Container>
        <ErrorPanel>{error.message}</ErrorPanel>
      </Container>}

      { loading && <WeFixItLoadingState />}
      { !loading && !error &&  (tickets.length === 0 ? <WeFixItNotFound message="We are sorry but there are no results found! 🤐🤐<br /> Please contact us via +9779845207927 or wefixitnepal@gmail.com for any inconvenience caused." /> : tickets.map(item=>
        <SearchResult key={item.id}>

          <h3 className="id">#{item.id}</h3>
          <p className="status">Status: {
            (item.status === 1) ? 'Open' : (item.status === 2) ? 'Pending' : 'Closed'
          }</p>
          <section class="discussion">
            {/* courtesy of J.M. Clery from the world of 2020. https://codepen.io/jmpp/pen/mprGZo */}
              <div className={`bubble sender first`}>On going!</div>
              <div className="bubble sender last">We are currently checking to see the issue described.</div>

              {item.remark ? <div className="bubble sender first">{item.remark}</div> :  ''}

              {item.remark && item.job_done === 1 ? <div className="bubble sender last">Pleasure doing business with you!</div> : ''}
              {item.remark && item.job_done === 0 ? <div className="bubble sender last">Sorry but we couldn't fix your phone!</div> : ''}




          </section>
          <p className="updated_at">Last Updated On: {item.updated_at.split('T')[0]}</p>
          <div>
            {item.images ? item.images[0]? <img src={getAssetPath(item.images[0])} width="100" alt="" /> : '':''}
            {item.images ? item.images[1]? <img src={getAssetPath(item.images[1])} width="100" alt="" /> : '':''}
            {item.images ? item.images[2]? <img src={getAssetPath(item.images[2])} width="100" alt="" /> : '':''}
          </div>
          {item.billing_amount ? <div style={{
            display: 'flex',
            width: '100%',
            justifyContent: 'space-around',
            flexWrap: 'wrap',
            alignItems:'center',
            padding: 10,
            background: '#ffffffae',
            borderRadius: 10,
            margin: '10px 0',
          }}>
            <p className="updated_at" style={{marginBottom: 10}}>Billing Amount: <code style={{
              background: '#eeaeff',
              fontSize: 16,
              padding: 5,
              borderRadius: 10,
              marginBottom:10,
            }}>NRS. {item.billing_amount}</code></p>
            <p className="updated_at" style={{marginBottom: 10}}>Discount: {item.discount}%</p>
            <p className="updated_at" style={{marginBottom: 10}}>Payable Amount: <code style={{
              background: '#eeaeff',
              fontSize: 16,
              padding: 5,
              borderRadius: 10,
            }}>NRS. {item.payable_amount}</code></p>
            <WeFixItButton onClick={_ => window.open(`${apiPath}ticket/invoice/${item.invoice_id}/false`, '_blank')}>View Invoice</WeFixItButton>
          </div> : '' }

        </SearchResult>)
      )}
    </SearchPageContainer>
  )
}

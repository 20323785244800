
import React from 'react'
import { Map, TileLayer, Marker, Popup } from 'react-leaflet';

const WeFixItMap = ({lat, lng}) => {
  const state = {
    lat,
    lng,
    zoom: 16,
  }

  const position = [state.lat, state.lng]
  return(
    <div className="mapouter">
      <div className="gmap_canvas">
        <iframe
          width={'100%'}
          height={500}
          id="gmap_canvas"
          src="https://maps.google.com/maps?q=wefixitstore&t=&z=13&ie=UTF8&iwloc=&output=embed"
          frameBorder={0}
          scrolling="no"
          marginHeight={0}
          marginWidth={0}
        />
        <a href="https://123movies-to.org" />
        <br />
        <style
          dangerouslySetInnerHTML={{
            __html:
              ".mapouter{position:relative;text-align:right;height:500px;width:100%;}"
          }}
        />
        <a href="https://www.embedgooglemap.net">map embed code</a>
        <style
          dangerouslySetInnerHTML={{
            __html:
              ".gmap_canvas {overflow:hidden;background:none!important;height:500px;width:100%;}"
          }}
        />
      </div>
    </div>

  )
}

export default WeFixItMap;
import React from 'react'
import styled from 'styled-components';

const WeFixItCategoryContainer = styled.div`
  border-radius: 5px;
  display:flex;
  justify-content: space-around;
  align-items: center;
  flex-direction: column;
  min-width: 290px;
  max-width: 290px;
  margin: 20px;
  box-shadow: -2px 3px 37px -10px rgba(0,0,0,0.14);
  cursor: pointer;
  transition: box-shadow .2s ease-out;
  &:hover {

    box-shadow: -3px 3px 37px -10px rgba(0,0,0,0.24);
  }

  @media (max-width: 800px) {
    min-width: 100%;
    max-width: 100%;
  }
`;

const WeFixItImageContainer = styled.div`
  position: relative;
  margin-top: 30px;
  width: 100%;
  display: flex;
  justify-content: center;
`;
const WeFixItCategoryTitle = styled.div`
  margin-top: 10px;
`;
const WeFixItCategoryDetail = styled.div`
  text-align: center;
  max-width: 90%;
  font-size: 15px;
  margin-bottom: 30px;
  margin-top: 10px;
`;


export default function WeFixItCategory({src, alt, title, description}) {
  return (
    <WeFixItCategoryContainer>
      <WeFixItImageContainer>
        <img style={{objectFit: 'cover', height: '100px',}} src={src} alt={alt} />
      </WeFixItImageContainer>
      <WeFixItCategoryTitle dangerouslySetInnerHTML={{__html: title}}></WeFixItCategoryTitle>
      <WeFixItCategoryDetail>
        {description}
      </WeFixItCategoryDetail>
    </WeFixItCategoryContainer>
  )
}

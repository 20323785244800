import React, { useState, useEffect } from 'react';

import {NavLink} from 'react-router-dom';
import Http from '../Http';

import {
  ContactFormContainer,
  ContactForm,
  FormGroup,
  ContactUsContainer,
  ContactUsSection,
  YetiTechCreditLink,
  SocialLink,
  FinalContainer,
  WeFixItImageContainer
} from './wrapper';

import WeFixItImage from './WeFixItImage';
import WeFixItButton from './WeFixItButton';
import WeFixItMap from './WeFixItMap';
import styled from 'styled-components';

const FormImageContainer = styled(WeFixItImageContainer)`
   width: 250px;
   position: absolute;
   bottom: -5px;

  &.left {
    left: 50px;
  }
  &.right {
    right: 50px;
  }
  @media (max-width: 1100px) {
    width: 225px;
  }
  @media (max-width: 1000px) {
    width: 200px;
  }
  @media (max-width: 993px) {
    &.left {
      width: 170px;
      left: -25px;
    }
    &.right {
      width: 150px;
      right: -25px;
    }
  }
  @media (max-width: 500px) {
    width: 100px;
    &.left {
      left: -25px;
    }
    &.right {
      right: -25px;
    }
  }
`;

export default function WeFixItFooter() {
  const [firstName, setFirstName] = useState('');
  const [firstNameError, setFirstNameError] = useState('');
  const [lastName, setLastName] = useState('');
  const [lastNameError, setLastNameError] = useState('');
  const [email, setEmail] = useState('');
  const [emailError, setEmailError] = useState('');
  const [repairTypeId, setRepairTypeId] = useState('');
  const [message, setMessage] = useState('');
  const [messageError, setMessageError] = useState('');
  const [successMessage, setSuccessMessage] = useState('');
  const [repairTypes, setRepairTypes] = useState([]);
  const [footerContent, setFooterContent] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  const isEmail = (mail) => {
    if (/^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/.test(mail)) {
      return true;
    }
    return false;
  }

  const formIsValid = () => {
    setFirstNameError('');
    setLastNameError('');
    setEmailError('');
    setMessageError('');

    if (firstName === '') {
      setFirstNameError('* Full Name is required.');
      return false;
    }
    if (lastName === '') {
      setLastNameError('* Last Name is required.');
      return false;
    }
    if (email === '') {
      setEmailError('* Email is required.');
      return false;
    }
    if (!isEmail(email)) {
      setEmailError('* You have entered an invalid email address!');
      return false;
    }
    if (message === '') {
      setMessageError('* Message is required.');
      return false;
    }
    return true;
  }

  const submitForm = () => {
    if (formIsValid()) {
      Http.post('/api/queries', {
        first_name: firstName,
        last_name: lastName,
        email,
        message,
        repair_type_id: repairTypeId,
      }).then(resp => {
        if (resp.status === 201) {
          setFirstName('');
          setLastName('');
          setEmail('');
          setMessage('');
          setSuccessMessage('Query Submitted Successfully! We will contact you via email.');
        }
      });
    }
  }
  useEffect(() => {
    Http.get('/api/get-repair-types').then(resp => setRepairTypes(resp.data));
    Http.get('/api/get-footer-content').then(resp => setFooterContent(resp.data.footerContent));
  }, []);

  useEffect(() => {
    setIsLoading(false)
  }, [footerContent]);

  return (
    !isLoading ?
      (<footer style={{ position: 'relative', marginTop: 150 }}>

        <h1 style={{ marginTop: 50, marginBottom: 30, textAlign: 'center' }}>Our <strong>Location</strong></h1>
        <div id="map" style={{ height: 400 }}>
          {footerContent.lat ?
            <WeFixItMap lat={parseFloat(footerContent.lat)} lng={parseFloat(footerContent.lon)} /> : ''}
        </div>
        <ContactFormContainer id="contact">
          <h1 style={{ marginTop: 50, marginBottom: 30, textAlign: 'center' }}>Facing Problems? <br></br> <strong>Lets Get In Touch Now</strong></h1>
          <ContactForm>
            <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
              <FormGroup style={{ width: '47%' }}>
                {firstNameError !== '' ? <span className="alert-danger">{firstNameError}</span> : ''}
                <label>First Name</label>
                <input className={firstNameError !== '' ? 'error' : ''} onChange={e => setFirstName(e.target.value)} value={firstName} type="text" placeholder="Robert Lee" />
              </FormGroup>
              <FormGroup style={{ width: '47%' }}>
                {lastNameError !== '' ? <span className="alert-danger">{lastNameError}</span> : ''}
                <label>Last Name</label>
                <input className={lastNameError !== '' ? 'error' : ''} onChange={e => setLastName(e.target.value)} value={lastName} type="text" placeholder="Anderson" />
              </FormGroup>
            </div>
            <FormGroup>
              {emailError !== '' ? <span className="alert-danger">{emailError}</span> : ''}
              <label>Email Address</label>
              <input className={emailError !== '' ? 'error' : ''} onChange={e => setEmail(e.target.value)} value={email} type="text" placeholder="robert.lee@gmail.com" />
            </FormGroup>
            <FormGroup>
              <label>Which Related Problem You Are Facing?</label>
              <select onChange={e => setRepairTypeId(e.target.value)} value={repairTypeId} >
                {repairTypes.map(type => <option key={type.id} value={type.id}>{type.display_name}</option>
                )}
              </select>
            </FormGroup>

            <FormGroup>
              {messageError !== '' ? <span className="alert-danger">{messageError}</span> : ''}
              <label>Type Your Message</label>
              <textarea className={messageError !== '' ? 'error' : ''} onChange={e => setMessage(e.target.value)} value={message} rows={5} placeholder="Lorem Ipsum is simply dummy text of the printing and..." />
            </FormGroup>
            <FormGroup>
              {successMessage !== '' ? <span className="alert-success">{successMessage}</span> : ''}
              <WeFixItButton onClick={submitForm}>Submit</WeFixItButton>
            </FormGroup>

          </ContactForm>
          <FormImageContainer className="left">
            <WeFixItImage src="../images/Layer 2"></WeFixItImage>
          </FormImageContainer>
          <FormImageContainer className="right">
            <WeFixItImage src="../images/2451375"></WeFixItImage>
          </FormImageContainer>
        </ContactFormContainer>

        <ContactUsContainer>
          <ContactUsSection>
            <h4><strong>Contact Us</strong></h4>
            <p className="contact-us-text">{footerContent.contact_number}</p>
            <p className="contact-us-text">{footerContent.footer_address}</p>
            <div className="contact-us-text">
              <WeFixItImageContainer style={{ width: 25, margin: 0, paddingRight: 5 }}>
                <WeFixItImage src='../images/contact' />
              </WeFixItImageContainer>
              {footerContent.footer_contact_email}
            </div>
          </ContactUsSection>
          <ContactUsSection>
            <h4><strong>Our Company</strong></h4>
            <a rel="noopener noreferrer" className="our-company-links" href="#about">About</a>
            <a rel="noopener noreferrer" className="our-company-links" href="#our-services">Our Services</a>
            <a rel="noopener noreferrer" className="our-company-links" href="#portfolio">Portfolio</a>
            <a rel="noopener noreferrer" className="our-company-links" href="#map">Location</a>
            <NavLink className="our-company-links" to="blog">Blog</NavLink>
          </ContactUsSection>
          <ContactUsSection>

            <h4><strong>Social Contacts</strong></h4>
            {footerContent.facebook_page_url && <a  rel="noopener noreferrer" className="social-contact-links" target="_blank" href={footerContent.facebook_page_url}>Facebook</a>}
            {footerContent.linked_in_profile_url && <a  rel="noopener noreferrer" className="social-contact-links" target="_blank" href={footerContent.linked_in_profile_url}>LinkedIn</a>}
            {footerContent.youtube_channel_url && <a  rel="noopener noreferrer" className="social-contact-links" target="_blank" href={footerContent.youtube_channel_url}>Youtube</a>}
            {footerContent.vimeo_channel_url && <a  rel="noopener noreferrer" className="social-contact-links" target="_blank" href={footerContent.vimeo_channel_url}>Vimeo</a>}
            {footerContent.skype_id && <a  rel="noopener noreferrer" className="social-contact-links" target="_blank" href={footerContent.skype_id}>Skype</a>}
          </ContactUsSection>
          <ContactUsSection>
            <h4><strong>Address</strong></h4>
            <p className="address">New road, Kathmandu Bagmati, Nepal</p>

            <h4><strong>Follow Us</strong></h4>
            <div style={{ display: 'flex' }}>
              {footerContent.facebook_page_url && <SocialLink  rel="noopener noreferrer" href={footerContent.facebook_page_url} target="_blank" primary="#7266d8" secondary="#c07ddd">
                  <WeFixItImage style={{ width: 7 }} src="../images/facebook" alt="wefixit facebook" />
              </SocialLink>
              }
              {footerContent.skype_id && <SocialLink  rel="noopener noreferrer" href={footerContent.skype_id} target="_blank" primary="#3bbf76" secondary="#7fdc93">
                <WeFixItImage style={{ width: 15 }} src="../images/skype" alt="wefixit skype" />
              </SocialLink>
              }
              {footerContent.linked_in_profile_url && <SocialLink  rel="noopener noreferrer" href={footerContent.linked_in_profile_url} target="_blank" primary="#f86ea8" secondary="#f13b97">
                <WeFixItImage style={{ width: 15 }} src="../images/linkedin" alt="wefixit linkedin" />
              </SocialLink>
              }
              {footerContent.instagram_profile_url && <SocialLink  rel="noopener noreferrer" href={footerContent.instagram_profile_url} target="_blank" primary="#f09676" secondary="#f0c578">
                <WeFixItImage style={{ width: 15 }} src="../images/instagram" alt="wefixit instagram" />
              </SocialLink>
              }
            </div>
          </ContactUsSection>
        </ContactUsContainer>

        <FinalContainer>
          <YetiTechCreditLink href="#">Created by Yeti Tech</YetiTechCreditLink>
          <WeFixItImageContainer style={{ width: 300, position: 'absolute', bottom: 0, left: 100, zIndex: -1 }}>
            <WeFixItImage width={50} src="../images/Group 2254" alt="footer" />
          </WeFixItImageContainer>
          <WeFixItImageContainer style={{ width: 300, position: 'absolute', bottom: 0, right: 100, zIndex: -1 }}>
            <WeFixItImage width={50} src="../images/Group 2252" alt="footer" />
          </WeFixItImageContainer>
        </FinalContainer>
      </footer>) : ''
  )
}

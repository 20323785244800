import React from 'react';
import styled from 'styled-components';

import notFoundImage from './ClipartKey_1596771.png';

const NotFound = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  & > div{
    position: relative;
    padding: 20px;
    background: var(--primary-color);
    background: linear-gradient(90deg,var(--primary-color) 0%,var(--primary-color-2) 100%);
    border-radius: 10px;
    margin: 20px 0;
    text-align: center;
    font-size: 1.5rem;
    max-width: 500px;
    > * {
      color: white;
    }
    &:before {
      border: 48.5px solid transparent;
      border-top: 48.5px solid var(--primary-color-2);
      border-bottom: 0;
      height: 0;
      width: 0;
      z-index: -1;
      border-top-width: 60px;
      content: '';
      display: block;
      position: absolute;
      left: 25rem;
      bottom: -35px;
      transform-origin: center;
      transform: rotate(132deg) skew(-25deg) translateY(16.6666666667px);
    }
  }
`;


const WeFixItNotFound = ({ message }) => {
  return (
    <NotFound>
      <div>
        <h3>Oops!</h3>
        <p dangerouslySetInnerHTML={{__html: message}} />
      </div>
      <img width={150} src={notFoundImage} alt="result not found" />
    </NotFound>);
}

export default WeFixItNotFound;
import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import WeFixItLoadingState from '../../Components/WeFixItLoadingState';
import WeFixItNotFound from '../../Components/WeFixItNotFound';
import WeFixItPageTitle from '../../Components/WeFixItPageTitle';
import Http from '../../Http';
import {Pagination} from 'react-laravel-paginex'
import ErrorPanel from '../../Components/ErrorPanel';
import { Container } from '../HomePage/wrapper';

// import blog from './pnghut_cartoon-child-clip-art-male-happy-students_PwSVs9C12n.png';

const BlogPageContainer = styled.div`
  display: flex;
  align-items: center;
  overflow: hidden;
  min-height: 100vh;
  justify-content: flex-start;
  flex-direction: column;
  & > h1 {
    margin-top: 50px;
    margin-bottom: 30px;
    text-align: center;
  }
  & > .pagination {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 5rem;
    margin: 3rem;
    list-style: none;
    border-radius: 0.6rem;
    background: #ffffff;
    box-shadow: 0 0.8rem 2rem rgba(#5a6181, 0.05);

    & > .__numbers,
    & > .__btn {
      display: flex;
      justify-content: center;
      align-items: center;
      margin: 0.8rem;
      font-size: 1.4rem;
      cursor: pointer;
    }

    & > .__numbers {
      width: 2.6rem;
      height: 2.6rem;
      border-radius: 0.4rem;

      &:hover {
        & > a{
          color: var(--primary-color);
        }
      }

      &.active {
        & > a{
          color: #ffffff;
        }
        background: var(--primary-color);
        font-weight: 600;
        border: 1px solid var(--primary-color);
      }
    }

    & > .__btn {
      & > a{
        color: var(--dark-color);
      }

      &:hover {
        & > a{
          color: var(--primary-color);
        }
      }
      &.active {
        & > a{
          color: var(--dark-color);
        }
        pointer-events: initial;

      }
    }
  }
`;

const BlogsContainer = styled.div`
  display: grid;
  align-items: center;
  justify-content: center;
  grid-template-columns: 1fr 1fr 1fr;
  width: 100%;
  padding: 0 200px;
  grid-gap: 50px;
  @media (max-width: 1150px) {
    padding: 0 100px;
  }
  @media (max-width: 950px) {
    padding: 0 70px;
    grid-template-columns: 1fr 1fr;
  }
  @media (max-width: 700px) {
    grid-template-columns: 1fr;
  }
  @media (max-width: 550px) {
    grid-template-columns: 1fr;
    padding: 0 20px;
  }
`;

const BlogItem = styled.div`
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  min-height: 200px;
  background: white;
  padding: 20px;
  margin-bottom: 20px;
  position: relative;
  border-radius: 10px;
  box-shadow: -2px 3px 37px -10px rgba(0,0,0,0.14);
  transition: box-shadow .2s ease-out;
  cursor: pointer;
  &:hover {
    box-shadow: -3px 3px 37px -10px rgba(0,0,0,0.34);
  }
  & > div .cover_image {
    height: 200px;
    width: 100%;
    object-fit: cover;
  }
  & > .title {
    margin: 20px 0 10px 0;
  }

  & > .author {
    color: #9d9999;
    margin: 0 0 10px 0;
    font-size: 12px;
  }

  & > .description,
  & > .short-description {
    color: #555555
  }
  & > .description,
  & > div .blog-title{
    visibility: hidden;
    width: 0;
    height: 0;
  }

  & > .view-details {
    color: #49be55;
    background: #00000000;
    border: none;
    cursor: pointer;
    padding: 13px 0px;
    font-weight: 600;
    &:hover {
      background: #0000001e;
    }
  }
  & > .image-container {
    maxHeight: 200px;
    minHeight: 200px;
    overflow: hidden;
  }
  &.active {
    position: fixed;
    padding: 200px;
    @media (max-width: 950px) {
      padding: 70px;
      grid-template-columns: 1fr 1fr;
    }
    @media (max-width: 700px) {
      grid-template-columns: 1fr;
    }
    @media (max-width: 550px) {
      grid-template-columns: 1fr;
      padding: 20px;
    }
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 900;
    height: 100vh;
    overflow-y: scroll;
    align-items: center;
    & > div.image-container {
      position: absolute;
      width: 100%;
      top: 0;
      min-height: 400px;
      max-height: 400px;
    }
    .title {
      margin-top: 1950px;
    }

    & > div .cover_image {
      max-height: 400px;
      min-height: 400px;
      width: 100%;
      object-fit: cover;
      z-index: -1;
    }
    & > div .blog-title {
      font-size: 3rem;

      @media (max-width: 700px) {
        font-size: 2.5rem;
      }
      @media (max-width: 550px) {
        font-size: 2rem;
      }
      background: #ffffffae;
      backdrop-filter: blur(5px);
      border: 1px solid #80808014;
      padding: 10px;
      position: absolute;
      top: 200px;
      left: 70px;
    }

    & > .author {
      color: #9d9999;
      margin: 0 0 10px 0;
      font-size: 12px;
    }

    & > .description{
      display: flex;
      position: absolute;
      top: 425px;
      padding:0 4em;
      margin-bottom: 50px;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      & > p {
        letter-spacing: -0.003em;
        font-size: 21px;
        margin-bottom: -0.46em;
        letter-spacing: -0.004em;
        line-height: 1.58;
        font-style: normal;
        color: rgba(41, 41, 41, 1);
        word-break: break-word;
        font-weight: 400;
      }
      & > h1 ,
      & > h2 ,
      & > h3 ,
      & > h4 ,
      & > h5 ,
      & > h6 {
        letter-spacing: 0;
        line-height: 28px;
        margin-top: 1.72em;
        font-size: 22px;
        font-weight: 800;
        margin-bottom: 1.72em;
        letter-spacing: -0.022em;
        font-style: normal;
        color: rgba(41, 41, 41, 1)
      }
    }
    & > .description,
    & > div .blog-title {
      visibility: visible;
      width: auto;
      height: auto;
    }

    & > .view-details,
    & > .short-description,
    & > .title {
      visibility: hidden;
      width: 0;
      height: 0;
    }
  }
`;

function BlogPage() {
  const [blogs, setBlogs] = useState([])
  const [assetPath, setAssetPath] = useState([])
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState();

  useEffect(()=> {
    Http.get(`api/blogs?paginationCount=6`).then(resp => {
      setBlogs(resp.data.blogs);

      Http.get('api/get-asset-path').then(resp => {
        setAssetPath(resp.data.asset_path)
        setLoading(false);
      }).catch(err => {
        setError(err);
        setLoading(false);
      });
    }).catch(err => {
      setError(err);
      setLoading(false);
    });
  }, []);

  const getAssetPath = (path) => assetPath + '/' + path ;

  const getData=(data)=>{
    Http.get(`api/blogs?paginationCount=6&page=${data.page}`).then(resp => {
      setBlogs(resp.data.blogs);
    });
  }
  return (
    <BlogPageContainer id="blog-page-container">
      <WeFixItPageTitle pageName="Blog" />

      {Boolean(error) && <Container>
        <ErrorPanel>{error.message}</ErrorPanel>
      </Container>}

      { loading && <WeFixItLoadingState />}
      { !loading && !Boolean(error) && <BlogsContainer id="blog-container">
          {blogs.data.map(item => <BlogItem id={`blog-item-${item.id}`} className="blog-item" key={item.id} onClick={e => {
                  e.currentTarget.classList.toggle('active')
                  document.querySelectorAll('body')[0].style.overflow = e.currentTarget.classList.contains('active') ? 'hidden' : '';
                }}>
                  <div className="image-container">
                    <img class="cover_image" src={getAssetPath(item.cover_image)} alt={`${item.title} cover `} ></img>
                    <h3 className="blog-title"><strong>{item.title}</strong></h3>
                  </div>
                  <h3 className="title"><strong>{item.title}</strong></h3>
                  <p className="author">By {item.author} {item.created_at.split('T')[0]}</p>
                  <p className="short-description" dangerouslySetInnerHTML={{__html: item.description.substr(0, 150)+'...' }} />
                  <div className="description" dangerouslySetInnerHTML={{__html: item.description}} />
                  <button className="view-details">View Details</button>
                </BlogItem>
          )}
      </BlogsContainer>}
      {
        !loading && !error && blogs.data.length === 0 && <WeFixItNotFound message="wefixit nepal, We are sorry but there are no results found! 🤐🤐<br /> Please contact us via +9779845207927 or wefixitnepal@gmail.com for any inconvenience caused." />
      }

        {loading ? '' :
          <Pagination changePage={getData}
            data={blogs}
            containerClass={"pagination"}
            prevButtonClass={"__btn"}
            nextButtonClass={"__btn"}
            numberButtonClass={'__numbers'}
            numbersCountForShow={1}
        />}
    </BlogPageContainer>
  )
}
export default React.memo(BlogPage);

/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';
import { AlertPanelContainer } from './wrapper';

export default function ErrorPanel({children}) {
  return (
    <AlertPanelContainer>
      <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" strokeLinecap="round" strokeLinejoin="round" className="feather feather-alert-octagon"><polygon points="7.86 2 16.14 2 22 7.86 22 16.14 16.14 22 7.86 22 2 16.14 2 7.86 7.86 2"></polygon><line x1="12" y1="8" x2="12" y2="12"></line><line x1="12" y1="16" x2="12.01" y2="16"></line></svg>
      {children}<br></br><br></br>
      <p>Click <a onClick={() => window.location.reload()}>here</a> to refresh the page.</p>
    </AlertPanelContainer>
  )
}

import React from 'react'
import styled from 'styled-components';

const WeFixItInputContainer = styled.div`
  border: 1px solid #bd7cddee;
  border-radius: 3px;
  margin: 0 30px;
  display: flex;
  align-items: center;
  > input{
    border: none;
    border-top-style: hidden;
    border-right-style: hidden;
    border-left-style: hidden;
    padding: 5px 10px;
    font-size: 14px;
    border-bottom-style: hidden;
    outline: none;
  }
  > input::placeholder {
    opacity: .6; /* Firefox */
  }
  > a { height: 100% }
  > svg, > a svg {
    height: 100%;
    padding: 7px;
    stroke: #bd7cddee;
    transition: all .3s ease-out;
    cursor: pointer;
    border-top-right-radius: 3px;
    border-bottom-right-radius: 3px;
    &:hover {
      stroke: #bd7cdd;
      background: #0000000a
    }
    &:active {
      stroke: #bd7cdd;
      background: #0000001a;
    }
  }
`;

export default function WeFixItInput({type, placeholder, icon, value, onChange, onKeyUp}) {
  return (
    <WeFixItInputContainer>
      <input onKeyUp={onKeyUp} placeholder={placeholder} type={type} onChange={onChange} value={value} />
      {icon}
    </WeFixItInputContainer>
  )
}

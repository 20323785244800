import React, { useEffect, useState } from 'react';
import './slick.css';
import './slickTheme.css';

import "./image-gallery.css";

import Http from '../../Http';

import WeFixItButton from './../../Components/WeFixItButton';
import WeFixItImage from './../../Components/WeFixItImage';
import WeFixItCategory from './../../Components/WeFixItCategory';
import WeFixItPageTitle from './../../Components/WeFixItPageTitle';
import ImageGallery from 'react-image-gallery';

import DropOff from './../../Components/DropOff';
import Giving from '../../Components/giving.png'
import Loan from '../../Components/loan.png'
import Repair from '../../Components/repairing.png'

import {
  Container,
  Banner,
  WeFixItPageTitleSecondary,
  WeFixItBannerTitlePrimary,
  WeFixItPageDescription2,
  WeFixItPageDescription,
  WeFixItButtonContainer,
  WeFixItUnderlay,
  WeFixItProcessContainer,
  WeFixItHereToHelpContainer,
  WeFixItSpecialitySlider,
  WeFixItGalleryGrid,
  WeFixItTestimonialContainer,
  WeFixItTestimonialLegend,
  WeFixItTestimonialItem,
} from './wrapper';

import WeFixItLoadingState from '../../Components/WeFixItLoadingState';
import ErrorPanel from '../../Components/ErrorPanel';
import { Image, Container as ChakraContainer, Grid, GridItem, Box, Text, Center, Flex } from '@chakra-ui/react';
import Slider from 'react-slick';


const slickPrevArrow = <div>
  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="1" strokeLinecap="round" strokeLinejoin="round" className="feather feather-arrow-left"><line x1="19" y1="12" x2="5" y2="12"></line><polyline points="12 19 5 12 12 5"></polyline></svg>
</div>
const slickNextArrow = <div>
  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="1" strokeLinecap="round" strokeLinejoin="round" className="feather feather-arrow-right"><line x1="5" y1="12" x2="19" y2="12"></line><polyline points="12 5 19 12 12 19"></polyline></svg>
</div>

function Index() {
  const settings = {
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    dots: false,
    prevArrow: slickPrevArrow,
    nextArrow: slickNextArrow,
    responsive: [
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1
        }
      },
      {
        breakpoint: 1008,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1
        }
      },
      {
        breakpoint: 800,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1
        }
      }

    ]
  };

  let images = [];
  const [pageContent, setPageContent] = useState({});
  const [deviceTypes, setDeviceTypes] = useState([]);
  const [testimonials, setTestimonials] = useState({});
  const [currentTestimonial, setCurrentTestimonial] = useState();
  const [testimonial, setTestimonial] = useState();
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState();
  const [showGallery, setShowGallery] = useState(false);
  const [clickedIndex, setClickedIndex] = useState(false);

  useEffect(() => {
    if (showGallery) {
      document.querySelectorAll('body')[0].style.overflowY = 'hidden';
      window.scroll(0, 0);
    } else {
      document.querySelectorAll('body')[0].style.overflowY = 'auto';
      document.getElementById("gallery-grid") && document.getElementById("gallery-grid").scrollIntoView();
    }
  }, [showGallery]);

  useEffect(() => {
    if (pageContent.gallery) {
      pageContent.gallery.map(item => {
        let temp = {
          original: item.image,
          thumbnail: item.thumbnailUrl,
          originalTitle: item.title,
          thumbnailLabel: item.title,
          description: <h2>{item.title} <br></br> <small>{item.caption}</small></h2>,
        };
        images.push(temp);
      });
    }
  }, [showGallery])

  useEffect(() => {
    Http.get('/api/get-homepage-data').then( resp => {
      if(resp.status === 200){
        setPageContent(resp.data.page_content);
        setDeviceTypes(resp.data.device_types);
        setTestimonials(resp.data.testimonials);
        setCurrentTestimonial(0);
        setLoading(false);
      }
    }).catch(err => {
      setError(err);
      console.log([err])
      setLoading(false);
    });

  }, [])

 
  useEffect(() => {
    for(let j = 0; j < Object.keys(testimonials).length; j++) {
      let item = testimonials[j];
      if(parseInt(j) === parseInt(currentTestimonial)) {
        setTestimonial(item);
      }
    }
  }, [currentTestimonial, testimonials])

  const handleTestimonialItemClick = (e) => {
    let element = e.currentTarget;
    let id = element.dataset.id;
    let center_testimonial = document.getElementById('center-testimonial');

    let rect = center_testimonial.getBoundingClientRect();

    element.style.top = rect.top+50+'px';
    element.style.left = rect.left+50+'px';
    element.style.top = rect.top-50+'px';
    element.style.right = rect.right-50+'px';
    element.style.opacity = 0;

    setTimeout(()=> {
      setCurrentTestimonial(id)
    }, 300)
  }

  const handleTestimonialPrevious = () => {
    if(currentTestimonial <= testimonials.length && currentTestimonial >= 1) {
      setCurrentTestimonial(currentTestimonial-1);
    } else {
      setCurrentTestimonial(testimonials.length);
    }
  }

  const handleTestimonialNext = () => {
    if(currentTestimonial < testimonials.length) {
      setCurrentTestimonial(currentTestimonial+1);
    } else {
      setCurrentTestimonial(1);
    }
  }

  const [dropOffFormVisible, setDropOffFormVisible] = React.useState(false);

  const getSrc = src => {
    var width = (window.innerWidth > 0) ? window.innerWidth : window.screen.width;
    if(width <= 720) {
      return src+'.png';
    }
    return src+'@2x.png';
  }

  const settingz = {
    dots: false,
    fade: false,
    infinite: true,
    speed: 400,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true
  };

  useEffect(() => {
    document.querySelectorAll(".slick-prev").forEach((el) => {
      el.style.setProperty("bottom", "-10%", "important");
      el.style.setProperty("z-index", "120", "important");
    });
    document.querySelectorAll(".slick-next").forEach((el) => {
      el.style.setProperty("bottom", "-10%", "important");
    });
  });

  return (
    <div style={{ minHeight: '100vh' }} >
      { Boolean(error) && <Container>
        <ErrorPanel>{error.message}</ErrorPanel>
      </Container>}

      { loading && <>
        <WeFixItPageTitle pageName="Loading..." />
        <WeFixItLoadingState />
      </>}
      {
        !Boolean(error) && !loading &&
        <>

          {
            deviceTypes.length < 3 ||
              !pageContent.gallery.length ||
              !testimonials.length ?
              <Container>
                <h1 style={{ margin: '50px 0', backgroundColor: '#747bfd', maxWidth: '70%', color: 'white', padding: 20, borderRadius: 10 }}>
                    Your site needs to be setup properly to work.<br /><br /><small> Please add at least 3 Device Types, 1 Gallery Image, 1 Testimonial Item from the admin panel for this warning to disappear. To edit the location of the map and the footer contact details, goto Page Settings and under the Site section, you can change various things about the site.</small>
                </h1>
              </Container> : <>
                  <DropOff isVisible={dropOffFormVisible} handleClose={() => setDropOffFormVisible(false)}></DropOff>
                  <Slider {...settingz}>
                    <Box>
                      <Grid templateColumns='repeat(1, 1fr)'>
                        <GridItem colSpan={1} >
                          <Banner style={{alignItems:'center',backgroundImage:"url("+pageContent.banner_image+")"}}>
                            <Box 
                              // pl={{base:'250px', md:'200px', sm:'0px'}} 
                              width={{base:'50%',md:'50%',sm:'100%'}}
                              alignItems={'center'}
                              >
                              <WeFixItBannerTitlePrimary dangerouslySetInnerHTML={{ __html: pageContent.banner_title }} />
                              <Text display={{base:'block',md:'block',sm:'none'}} style={{background: '#836bda94',padding: '10px',borderRadius: '10px'}} mt={'2rem'} fontSize={'16px'} color={'white'} textAlign={{base:'center',sm:'center',md:'center'}} dangerouslySetInnerHTML={{ __html: pageContent.banner_description }} ></Text>
                              
                              <Flex justifyContent={'center'} gap={'10px'} marginTop={'4rem'} flexWrap={'wrap'}>
                                <Box className='bannerButton' onClick={() => {
                                  setDropOffFormVisible(true)
                                }}>
                                  <img src={Giving} style={{width:'60px',marginBottom:"10px"}} />
                                  DROP OFF
                                </Box>
                                <Box className='bannerButton' onClick={() => { window.location = "https://repair.wefixitstorenepal.com/valuation";}}>
                                  <img src={Loan} style={{width:'60px',marginBottom:"10px"}} />
                                  SELL PHONE
                                </Box>
                                <Box className='bannerButton' onClick={() => { window.location = "https://repair.wefixitstorenepal.com/repair"; }}>
                                  <img src={Repair} style={{width:'60px',marginBottom:"10px"}} />
                                  REPAIR PHONE
                                </Box>
                              </Flex>

                              {/* <WeFixItButtonContainer>
                                
                                <WeFixItButton onClick={() => { window.location.hash = ""; window.location.hash = "our-works" }} >Our Works</WeFixItButton>
                                <WeFixItButton outline onClick={() => {
                                  setDropOffFormVisible(true)
                                }}>Drop Off</WeFixItButton>
                              </WeFixItButtonContainer> */}
                            </Box>
                          </Banner>
                        </GridItem>
                      </Grid>
                    </Box>  

                      <Box>
                        <Grid templateColumns='repeat(1, 1fr)'>
                          <GridItem colSpan={1} >
                            <Banner style={{alignItems:'center',backgroundImage:"url("+pageContent.second_banner_image+")"}}>
                              <Box 
                                // pl={{base:'250px', md:'200px', sm:'0px'}} 
                                width={{base:'50%',md:'50%',sm:'100%'}}
                                alignItems={'center'}>
                                <WeFixItBannerTitlePrimary dangerouslySetInnerHTML={{ __html: pageContent.second_banner_title }} />
                                <Text display={{base:'block',md:'block',sm:'none'}} style={{background: '#836bda94',padding: '10px',borderRadius: '10px'}} mt={'2rem'}  fontSize={'16px'} color={'white'} textAlign={{base:'center',sm:'center',md:'center'}} dangerouslySetInnerHTML={{ __html: pageContent.second_banner_desc }} ></Text>
                                
                                <Flex justifyContent={'center'} gap={'10px'} marginTop={'4rem'} flexWrap={'wrap'}>
                                  <Box className='bannerButton' onClick={() => {
                                    setDropOffFormVisible(true)
                                  }}>
                                    <img src={Giving} style={{width:'60px',marginBottom:"10px"}} />
                                    DROP OFF
                                  </Box>
                                  <Box className='bannerButton' onClick={() => { window.location = "https://repair.wefixitstorenepal.com/valuation";}}>
                                    <img src={Loan} style={{width:'60px',marginBottom:"10px"}} />
                                    SELL PHONE
                                  </Box>
                                  <Box className='bannerButton' onClick={() => { window.location = "https://repair.wefixitstorenepal.com/repair"; }}>
                                    <img src={Repair} style={{width:'60px',marginBottom:"10px"}} />
                                    REPAIR PHONE
                                  </Box>
                                </Flex>

                                {/* <WeFixItButtonContainer>
                                  <WeFixItButton onClick={() => { window.location.hash = ""; window.location.hash = "our-works" }} >Our Works</WeFixItButton>
                                  <WeFixItButton outline onClick={() => {
                                    setDropOffFormVisible(true)
                                  }}>Drop Off</WeFixItButton> 
                                </WeFixItButtonContainer> */}
                              </Box>
                            </Banner>
                          </GridItem>
                        </Grid>
                      </Box>                  
                    </Slider>
                  
                <ChakraContainer maxW='8xl'>
                  <Box height={'50px'}></Box>

                  <WeFixItProcessContainer id="about">
                    <div>
                      <WeFixItPageTitleSecondary dangerouslySetInnerHTML={{ __html: pageContent.second_section_title }} />
                      <WeFixItPageDescription dangerouslySetInnerHTML={{ __html: pageContent.second_section_description }} />
                      <WeFixItButton onClick={() => { window.location.hash = ""; window.location.hash = "always-here" }}>Know More</WeFixItButton>

                    </div>
                    <WeFixItImage src="/images/Asset 1" alt="test" />

                  </WeFixItProcessContainer>
                  <WeFixItHereToHelpContainer id="always-here" style={{ marginTop: 50 }}>
                    <WeFixItImage src="/images/2840301" alt="test" />
                    <div>
                      <WeFixItPageTitleSecondary dangerouslySetInnerHTML={{ __html: pageContent.third_section_title }} />
                      <WeFixItPageDescription dangerouslySetInnerHTML={{ __html: pageContent.third_section_description }} />
                    </div>
                  </WeFixItHereToHelpContainer>
                  {deviceTypes.length > 0 &&
                    <>
                      <WeFixItPageTitleSecondary style={{ marginTop: 50, marginBottom: 30 }}>What is the <br /> <strong>Speciality Of Us? </strong></WeFixItPageTitleSecondary>
                      <WeFixItSpecialitySlider {...settings} id="our-services">
                        {deviceTypes.map(type =>
                          <WeFixItCategory
                            key={type.id}
                            src={type.image}
                            alt={type.description}
                            title={`${type.name} <strong>Repair</strong>`}
                            description={type.description}
                          ></WeFixItCategory>
                        )}
                        {deviceTypes.map(type =>
                          <WeFixItCategory
                            key={type.id}
                            src={type.image}
                            alt={type.description}
                            title={`${type.name} <strong>Repair</strong>`}
                            description={type.description}
                          ></WeFixItCategory>
                        )}
                      </WeFixItSpecialitySlider>
                    </>
                  }
                  {pageContent.gallery.length > 0 &&
                    <>
                      <WeFixItPageTitleSecondary id="our-works" style={{ marginBottom: 30, marginTop: 50 }}>Our Featured <strong>Gallery </strong></WeFixItPageTitleSecondary>
                      {
                        showGallery && <div style={{ position: 'absolute', top: 0, left: 0, right: 0, bottom: 0, background: '#000000ae', overflow: 'hidden' }}>
                          <button style={{ position: 'fixed', top: 20, left: 50, zIndex: 10000, padding: 10, fontSize: 20, cursor: 'pointer' }} onClick={() => setShowGallery(false)}>X</button>
                          <ImageGallery startIndex={clickedIndex} infinite showBullets lazyLoad items={images} />
                        </div>
                      }
                      <WeFixItGalleryGrid id="gallery-grid">
                        {
                          pageContent.gallery.map((image, i) => (
                            <img style={{ cursor: 'pointer' }} onClick={() => { setShowGallery(true); setClickedIndex(i) }} src={image.thumbnailUrl} alt={image.title} />
                          ))
                        }
                      </WeFixItGalleryGrid>
                    </>
                  }

                </ChakraContainer>
                {testimonials.length > 0 &&
                  <div id="portfolio">

                    <WeFixItPageTitleSecondary style={{ marginTop: 50, marginBottom: 30, textAlign: 'center' }}>What our <strong>client says</strong></WeFixItPageTitleSecondary>
                    <WeFixItTestimonialContainer>
                      <WeFixItTestimonialLegend>
                        {
                          testimonials.map(item => {
                            return item.id !== currentTestimonial ? <div key={item.id} onClick={handleTestimonialItemClick} data-id={item.id}>
                              <img data-id={item.id} style={{
                                borderRadius: '50%',
                                height: '100%',
                                width: '100%',
                              }} src={item.avatar} alt={`${item.name} review about wefixit nepal`} />
                            </div> : '';
                          })
                        }
                      </WeFixItTestimonialLegend>
                      <WeFixItTestimonialItem className="active" data-index="1">
                        <div style={{ height: 200, width: 200 }} id="center-testimonial">
                          <img style={{ height: '100%', borderRadius: '50%', width: '100%', objectFit: 'cover' }} src={testimonial.avatar} alt={`${testimonial.name} review about wefixit`} />
                        </div>
                        <p className="comment">
                          <WeFixItImage className="left" src="../images/quote-left-1" alt="quote left" />
                          <WeFixItImage className="right" src="../images/quote-left" alt="quote right" />
                          {testimonial.comment}
                        </p>

                        <p className="name">{testimonial.name}</p>
                        <p className="profession">{testimonial.profession}</p>
                        <div className="arrows" style={{ display: 'flex' }}>
                          <div data-role="none" className="slick slick-prev" onClick={handleTestimonialPrevious} style={{ transform: 'translate(0, 0)' }}>
                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="1" strokeLinecap="round" strokeLinejoin="round" className="feather feather-arrow-left">
                              <line x1="19" y1="12" x2="5" y2="12"></line>
                              <polyline points="12 19 5 12 12 5"></polyline>
                            </svg>
                          </div>
                          <div data-role="none" className="slick slick-next" onClick={handleTestimonialNext} style={{ transform: 'translate(0, 0)' }}>
                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="1" strokeLinecap="round" strokeLinejoin="round" className="feather feather-arrow-right">
                              <line x1="5" y1="12" x2="19" y2="12"></line>
                              <polyline points="12 5 19 12 12 19"></polyline>
                            </svg>
                          </div>
                        </div>
                      </WeFixItTestimonialItem>
                    </WeFixItTestimonialContainer>
                  </div>
                } </>
          }
        </>
      }

    </div>
  )
}

export default React.memo(Index);
import axios from 'axios';

const baseURL = 'https://admin.wefixitstorenepal.com/';
// const baseURL = 'http://localhost:8000/';

const getHeaders = async () => ({
  'X-Requested-With': 'XMLHttpRequest',
  'Access-Control-Allow-Origin': '*',
  'Access-Control-Allow-Methods': 'GET, POST, PUT, DELETE, OPTIONS'
});

const Http = axios.create({
  baseURL,
  headers: getHeaders(),
});

export default Http;
import React from 'react';
import styled from 'styled-components';

const getSrc = src => {
  var width = (window.innerWidth > 0) ? window.innerWidth : window.screen.width;
  if(width <= 720) {
    return src+'.png';
  }
  return src+'@2x.png';
}

const StyledImage = styled.img`
  width: 100%;
`


export default function WeFixItImage(props) {
  return (
    <StyledImage className={props.className} onClick={props.onClick} style={props.style} src={getSrc(props.src)} alt={props.alt}  />
  )
}

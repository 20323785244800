import styled from 'styled-components';

export const ContactFormContainer = styled.div`
  background: url("../images/contact us form background.png");
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  position: relative;
`;

export const ContactForm  = styled.div`
  border-radius: 15px;
  background: white;
  margin-bottom: 50px;
  box-shadow: -2px 3px 37px -10px rgba(0,0,0,0.14);
  padding: 45px;
  @media (min-width: 576px) {
    width: 100%;

  }
  @media (max-width: 992px) {
    width: 70%;
  }
  @media (min-width: 992px) {
    width: 50%;
  }
`;

export const FormGroup = styled.div`
  display: flex;
  flex-direction: column;
  margin: 20px 0;
  & > label {
    font-weight: 600;
    margin-bottom: 10px;
  }
  & > input, & > select,
  & > textarea  {
    padding: 10px 0;
    border: none;
    border-bottom: 2px solid rgb(226, 226, 226);
    &.error {
      border-color: #ff0000ae;
    }
  }
  & > select option {
    padding: 10px 0;
  }

  & > textarea {
    resize: none;
  }

  & > .alert-danger {
    background: #ff0000ae;
    color: white;
    padding: 10px;
    border-radius: 5px;
    font-size: 12px;
  }
  & > .alert-success {
    background: #00ffaaae;
    color: black;
    padding: 10px;
    border-radius: 5px;
    font-size: 12px;
    margin: 10px 0;
  }
`;

export const ContactUsContainer = styled.div`
  display: flex;
  align-items: flex-start;
  flex-wrap: wrap;
  justify-content: space-evenly;
  background: white;
  clip-path: polygon(0 0, 100% 0, 100% 70%, 0% 100%);
  width: 100%;
  min-height: 400px;
  padding-bottom: 150px;
  padding-top: 50px;
  @media (max-width: 574px) {
    align-items: baseline;
    padding-right: 30px;
    padding-left: 30px;
    justify-content: space-between;
  }
`;

export const SocialLink = styled.a`
  border-radius: 50%;
  width: 30px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 10px;
  background: ${props => props.primary};
  background: linear-gradient(45deg, ${props => props.primary} 0%, ${props => props.secondary} 100%);
  transition: opacity .3s ease-out;
  &:hover {
    opacity: 80%;
  }
`;

export const ContactUsSection = styled.div`
  display: flex;
  align-items: baseline;
  flex-direction: column;
  & > * {
    color: grey;
  }
  & > h4 {
    color: black;
    margin-bottom: 20px;
  }

  & > .contact-us-text,
  & > .our-company-links,
  & > .social-contact-links,
  & > .address {
    margin-bottom: 10px;
    display: flex;
    align-items: flex-end;
    font-size: 12px;
    width: 100%;
  }

  & > .our-company-links,
  & > .social-contact-links {
    &:hover {
      text-decoration: underline;
    }
  }
  & > .contact-us-text {
    text-align: right;
  }

  & > .our-company-links {
    text-align: left;
  }

  & > .address {
    max-width: 130px;
  }
`;

export const FinalContainer = styled.div`
  position: absolute;
  bottom: 0;
  z-index: -1;
  background: url("../images/contact us form background.png");
  height: 300px;
  width: 100%;
  transform: translateY(180px);
  display: flex;
  align-items: center;
  justify-content: center;
  @media(max-width: 574px) {
    transform: translateY(94px);
  }
`;


export const YetiTechCreditLink = styled.a`
  color: grey;
  text-decoration: underline;
  font-size: 12px;
  &:hover{
    text-decoration: none;
  }
`;

export const WeFixItImageContainer = styled.div`
  position: relative;
  margin-top: 20px;
  width: 30%;
`;

export const AlertPanelContainer = styled.div`
  background: #FE6847;
  backdrop-filter: blur(25px);
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 25px 20px;
  border-radius: 4px;
  color: #EDF7F6;
  font-weight: 900;
  text-transform: uppercase;
  letter-spacing: 1px;
  margin-top: 25px;
  svg {
    padding-bottom: 5px;
    margin-right: 3px;
    height: 40px;
    width: 40px;
  }
  p {
    margin-top: 15px;
  }

`;
import styled from 'styled-components';

const WeFixItButton = styled.button`
  border-radius: 5px;
  padding: 10px 20px;
  background: var(--primary-color);
  background: ${ props => props.outline ? 'transparent' : 'linear-gradient(90deg, var(--primary-color) 0%, var(--primary-color-2) 100%)'};
  cursor: pointer;
  border: ${ props => props.outline ? '1px solid var(--primary-color-2)' : 'none'};
  color: ${ props => props.outline ? 'var(--primary-color-2)' : 'var(--light-color)'};
  outline: none;
  transition: 0.2s opacity ease-out;

  &:hover{
    opacity: .9;
  }
  &:active{
    opacity: .6;
  }
`;

export default WeFixItButton;
import React, { useState } from 'react';

import {
  HashRouter as Router,
  Switch,
  Route,
} from "react-router-dom";

import HomePage from './Containers/HomePage';
import BlogPage from './Containers/BlogPage';
import SearchPage from './Containers/SearchPage';
import WeFixItFooter from './Components/WeFixItFooter'

import WeFixItNavbar from './Components/WeFixItNavbar';
import DropOff from './Components/DropOff';

import AppContext from './Context';

function App() {
  const [dropOffFormVisible, setDropOffFormVisible] = useState(false);
  return (
    <AppContext.Provider value={{ setDropOffFormVisible }}>
      <div className="App">
        <Router>
          <WeFixItNavbar></WeFixItNavbar>
          <Switch>
            <Route path="/blog">
              <BlogPage />
            </Route>
            <Route path="/search/:query/:type">
              <SearchPage />
            </Route>
            <Route path="/">
              <HomePage />
            </Route>
          </Switch>
          <WeFixItFooter></WeFixItFooter>
          <DropOff isVisible={dropOffFormVisible} handleClose={() => setDropOffFormVisible(false)}></DropOff>
        </Router>
      </div>
    </AppContext.Provider>
  );
}

export default App;

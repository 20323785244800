import styled from 'styled-components';

import Slider from "react-slick";

export const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
`;

export const Banner = styled.div`
  display: flex;
  // align-items: center;
  justify-content: center;
  flex-direction: column;
  height: 60vh;
  // width: 100%;
  // background: url('https://nayakam.com/wp-content/uploads/2020/09/course-single.jpg');
  background-size: cover;
    background-repeat: no-repeat;
  @media (max-width: 646px) {
    height: 60vh;
  }
`;

export const WeFixItBannerTitlePrimary = styled.h1`
  text-align: center;
  color:white;

  @media (max-width: 646px) {
    font-size: 1.1rem;
    text-align: center;
  }

  @media (max-width: 646px) {
    font-size: 1.1rem;
  }
  @media (min-width: 768px) {
    font-size: 1.4rem;
    // width: 70%;
  }
  @media (max-width: 992px) {
    font-size: 1.7rem;
  }
  @media (min-width: 992px) {
    font-size: 2rem;
    
  }
`;

export const WeFixItPageTitlePrimary = styled.h1`
  text-align: left;

  @media (max-width: 646px) {
    font-size: 1.1rem;
    text-align: center;
  }

  @media (max-width: 646px) {
    font-size: 1.1rem;
  }
  @media (min-width: 768px) {
    font-size: 1.4rem;
    // width: 70%;
  }
  @media (max-width: 992px) {
    font-size: 1.7rem;
  }
  @media (min-width: 992px) {
    font-size: 2rem;
    
  }
`;

export const WeFixItPageTitleSecondary = styled.h1`
  text-align: center;

  @media (min-width: 646px) {
    font-size: 1.1rem;
  }
  @media (min-width: 768px) {
    font-size: 1.4rem;
    // width: 70%;
  }
  @media (max-width: 992px) {
    font-size: 1.7rem;
  }
  @media (min-width: 992px) {
    font-size: 2rem;
  }
`;

export const WeFixItPageDescription = styled.p`
  text-align: center;
  max-width: 500px;
  margin-top: 20px;
  @media (max-width: 646px) {
    font-size: 1.1rem;
    text-align: center;
  }
`;

export const WeFixItPageDescription2 = styled.p`
  text-align: left;
  // max-width: 70%;
  margin-top: 20px;
  font-size:16px!important;
  @media (max-width: 646px) {
    font-size: 1.1rem;
    text-align: center;
  }
`;

export const WeFixItButtonContainer = styled.div`
  margin-top: 20px;
  display: flex;
  justify-content: left;
  flex-wrap: wrap;
  & > button {
    margin-right: 10px !important;
    margin-bottom: 10px !important;
  }

  @media (max-width: 646px) {
    width: 100%;
    justify-content:center;
  }

  @media (min-width: 646px) {
    width: 100%;
  }
  @media (min-width: 768px) {
    // width: 70%;
  }
  @media (max-width: 992px) {
    width: 100%;
  }
  @media (min-width: 992px) {
    width: 100%;
  }
`;

export const WeFixItImageContainer = styled.div`
  position: relative;
  margin-top: 20px;
  width: 30%;
`;
export const WeFixItUnderlay = styled.span`
  position: absolute;
  height: 100px;
  bottom: 0px;
  left: 0; right: 0;
  background: rgb(252,253,255);
  background: linear-gradient(45deg, rgba(252,253,255,1) 0%, rgba(201,229,254,1) 100%);
  z-index: -1;
  display: flex;
  align-items: center;
  justify-content: center;
  & > img {
    width: 100px;
  }
`;
export const WeFixItProcessContainer = styled.div`
  display: grid;
  margin-top: 20px;
  // width: 70%;
  align-items: center;
  justify-content: center;
  background-image:url('/images/top section (16_9).png');
  & p {
    margin: 10px 0;
    max-width: 85%;
  }
  @media (max-width: 768px) {
    grid-template-columns: 1fr;
    grid-template-rows: 1fr 1fr;
    & > img {
      width: 50%;
      justify-self: center;
    }
    & > div {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
    }
  }
  @media (min-width: 768px) {
    grid-template-columns: 1fr 1fr;
  }
  @media (max-width: 600px) {
    & > img {
      width: 100%;
    }
  }
`;
export const WeFixItHereToHelpContainer = styled.div`
  display: grid;
  // width: 70%;
  align-items: center;
  justify-content: center;
  & p {
    margin: 10px 0;
  }
  background-image:url('/images/top section (16_9).png');

  @media (max-width: 768px) {
    grid-template-columns: 1fr;
    grid-template-rows: 1fr 1fr;
    & > img {
      width: 50%;
      justify-self: center;
    }
    & > div {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
    }
  }
  @media (min-width: 768px) {
    grid-template-columns: 1.5fr 1fr;
  }
  @media (max-width: 600px) {
    & > img {
      width: 100%;
    }
  }
`;

export const WeFixItSpecialitySlider = styled(Slider)`
  display: flex;
  justify-content: space-between;
  // width: 70%;
  margin-bottom: 100px;
  background-image:url('/images/top section (16_9).png');
`;
export const WeFixItGalleryGrid = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: flex-start;
  // width: 70%;
  overflow: hidden;
  background-image:url('/images/top section (16_9).png');
  margin-bottom: 100px;
  > img{
    width: 200px;
    height: 200px;
    object-fit: cover;
  }
  @media (max-width: 700px) {
    height: auto;
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    // width: 70%;
    > img {
      height: 150px;
      width: 150px;
    }
  }
  @media (max-width: 550px) {
    height: auto;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    // width: 70%;
    > img {
      height: 100px;
      width: 100px;
      padding: 5px;
    }
  }
`;

export const WeFixItTestimonialContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  background-image:url('/images/top section (16_9).png');
  margin-bottom: 100px;
  width: 100%;
  position: relative;
  @media (max-width: 920px) {
    flex-direction: column;
  }
`;

export const WeFixItTestimonialLegend = styled.div`
  position: absolute;
  left: 0;
  right: 0;
  display: flex;
  flex-direction: row;
  height: 100%;
  & > div {
    position: absolute;
    cursor: pointer;
    transition: all .3s ease-out;
    border-radius: 50%;
    & > img {
      transition: all .3s ease-out;
      object-fit: cover;
    }
  }
  & > div:nth-child(1) {
    left: 150px;
    top: 20px;
    height: 100px;
    width: 100px;
  }
  & > div:nth-child(2) {
    left: 160px;
    height: 50px;
    width: 50px;
    top: 300px;
  }
  & > div:nth-child(3) {
    left: 50px;
    top: 200px;
    height: 75px;
    width: 75px;
  }
  & > div:nth-child(4) {
    right: 80px;
    top: 20px;
    height: 50px;
    width: 50px;
  }
  & > div:nth-child(5) {
    right: 90px;
    height: 100px;
    width: 100px;
    top: 300px;
  }
  & > div:nth-child(6) {
    right: 50px;
    top: 160px;
    height: 75px;
    width: 75px;
  }
  & > div:nth-child(7) {
    display: none;
  }
  & > div:hover {
    transform: scale(1.1);
  }

  @media (max-width: 920px) {
    bottom: 0;
    position: unset;
    display: flex;
    & > div {
      position: unset;
      height: 50px !important;
      width: 50px !important;
    }
  }
`;

export const WeFixItTestimonialItem = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  &:not(.active) {
    > * {
      display
    }
  }

  & > .comment {
    font-size: 1.5rem;
    max-width: 500px;
    text-align: center;
    margin: 20px 0;
    position: relative;
    min-height: 180px;
    & > img.left {
      position: absolute;
      top: -10px;
      left: -70px;
      width: 30px;
      @media (max-width: 574px) {
        top: -25px;
        left: -13px;
      }
    }
    & > img.right {
      position: absolute;
      top: -10px;
      right: -70px;
      width: 30px;
      @media (max-width: 574px) {
        top: 44px;
        right: -20px;
      }
    }
    @media (max-width: 920px) {
      min-height: 50px;
      font-size: 1.2rem;
    }
    @media (max-width: 686px) {
      min-height: 50px;
      max-width: 400px;
      font-size: .9rem;
    }
    @media (max-width: 574px) {
      min-height: 50px;
      max-width: 300px;
      font-size: .9rem;
    }
  }
  & > .name {
    font-weight: bold;
    margin-bottom: 10px;
  }

  & > .arrows {
    margin-top: 20px;
    justify-content: space-between;
    width: 100px;
    > div {
      cursor: pointer;
      &:hover {
        transform: scale(1.2);
      }
    }
  }
`;

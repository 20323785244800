/* eslint-disable jsx-a11y/anchor-is-valid */
import React,{ useState, useContext } from 'react'
import styled from 'styled-components';
import { NavLink, useHistory, useLocation } from "react-router-dom";

import WeFixItButton from './WeFixItButton';
import WeFixItInput from './WeFixItInput';

import AppContext from './../Context';
// @media (min-width: 576px) { ... }
// @media (min-width: 768px) { ... }
// @media (min-width: 992px) { ... }
// @media (min-width: 1200px) { ... }
import { Image, useDisclosure } from "@chakra-ui/react";
import {Button, Drawer, DrawerOverlay, DrawerContent, DrawerHeader, DrawerBody, Box} from  "@chakra-ui/react";

import { AiOutlineHolder } from "react-icons/ai";

const Nav = styled.nav`
  display: flex;
  min-height: 74px;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  @media (max-width: 992px) {
    justify-content: space-around;
  }
  @media (min-width: 1200px) {
    justify-content: center;
  }
`;

const LeftNav = styled.ul`
  display: flex;
  flex-direction: row;
  list-style: none;
  align-items:center;
  &.main-nav {
    @media (max-width: 552px) {
      font-size: 12px;
      margin-bottom: 20px;
      margin-top: 20px;
    }
    @media (min-width: 1200px) {
      display: flex;
    }
  }
  > li a {
    position: relative;
    padding: 5px 10px;
    font-weight: 500;
    :hover {
      color: var(--primary-color);
    }

    &::after{
      position: absolute;
      top: 30px;
      left: 50%;
      content: '';
      display: inline-block;
      width: 7px;
      height: 7px;
      border-radius: 50%;
      background-color: var(--primary-color);
      opacity: 0;
      transition: $transition;
    }

    &.active {
      color: var(--primary-color);

      &::after {
        opacity: 1;
      }
    }
  }
`;
const ContactUsButton = styled(WeFixItButton)`
  @media (max-width: 576px) {
    display: none;
  }
  display: '';

`;
const SearchInput = styled.div`
  @media (max-width: 576px) {
    margin: 0;
  }
  display: flex;
  justify-content: space-around;

`;


export default function WeFixItNavbar() {
  const [searchQuery, setSearchQuery] = useState('');
  const history = useHistory();
  const location = useLocation();

  const appContext = useContext(AppContext);

  const initSearch = () => {
    const type = searchQuery.includes('#') ? 'id' : 'phone';
    const q = searchQuery.replace('#', '')
    if (location.pathname.match('/search/*/*')) {
      history.replace(`/reload`);
      setTimeout(() => {
        history.replace(`/search/${q}/${type}`);
      });
    } else {
      history.push(`/search/${q}/${type}`);
    }
  }

  const goToHome = (e, hash) => {
    e.preventDefault();
    // if current page isn't home
    if(history.location.pathname !== '/') history.push('/'); // goto home

    // scroll to hash
    if (hash === 'drop-off') {
      // open form
      appContext.setDropOffFormVisible(true);
    } else {
      setTimeout(() => window.location.hash = '#' + hash, 4000);
    }
  }

  const { isOpen, onOpen, onClose } = useDisclosure()

  return (
    <>
      <header style={{position: 'relative'}} id="header">
        <Box display={{base:'block', sm:'none',md:'block'}}>
        <Image pos={'absolute'} height="60px" top="7px" left={{base:'200px', lg:'200px', md:'20px',mmd:'20px'}} src="https://admin.wefixitstorenepal.com/storage/settings/June2021/ND1aTD9Qc3Ttt39iPmNj.PNG" alt="wefixit" />
          <Nav>
            <LeftNav className="main-nav">
              <li>
                <NavLink to="/" exact>Home</NavLink>
              </li>
              <li>
                <a style={{cursor: 'pointer'}} onClick={e => goToHome(e, 'about')}>About</a>
              </li>
              <li>
                <a style={{cursor: 'pointer'}}  onClick={e => goToHome(e, 'portfolio')}>Portfolio</a>
              </li>
              <li>
                <a style={{cursor: 'pointer'}}  onClick={e => goToHome(e, 'drop-off')}>Drop Off</a>
              </li>
              <li>
                <a style={{cursor: 'pointer'}} target='_blank'  href='https://repair.wefixitstorenepal.com/valuation'>Sell Phone</a>
              </li>
              <li>
                <a style={{cursor: 'pointer'}} target='_blank'  href='https://repair.wefixitstorenepal.com/repair'>Repair Phone</a>
              </li>
              <li>
                <a href="#contact">Contact</a>
              </li>
              <li>
                <NavLink to="/blog">Blog</NavLink>
              </li>
            </LeftNav>
            <SearchInput>
              <WeFixItInput
                onChange={e=> setSearchQuery(e.target.value)}
                value={searchQuery}
                placeholder="Ticket ID or Phone"
                type="text"
                onKeyUp={e => (e.key === 'Enter' || e.keyCode === 13) && initSearch() }
                icon={
                  // eslint-disable-next-line jsx-a11y/anchor-is-valid
                  <a onClick={initSearch} >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                      stroke="currentColor"
                      strokeWidth="2"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      className="feather feather-search">
                        <circle cx="11" cy="11" r="8"></circle>
                        <line x1="21" y1="21" x2="16.65" y2="16.65"></line>
                    </svg>
                  </a>}
                />
            </SearchInput>
            <a href="#contact">
              <ContactUsButton>Contact Us</ContactUsButton>
            </a>
          </Nav>
        </Box>

        <Button display={{base:'none', sm:'block',md:'none'}} zIndex={'100'} onClick={onOpen} pos={'fixed'} top={'20px'}left="20px" bg={'linear-gradient(90deg,var(--primary-color) 0%,var(--primary-color-2) 100%)'} _hover={{bg:'linear-gradient(90deg,var(--primary-color-2) 0%,var(--primary-color) 100%)'}}>
          <AiOutlineHolder color='white' fontSize={'25px'} />
        </Button>

        <Drawer placement={'left'} onClose={onClose} isOpen={isOpen}>
          <DrawerOverlay />
          <DrawerContent>
            <DrawerHeader borderBottomWidth='1px'>
              <a href="/">
                  <Image  height="80px" src="https://admin.wefixitstorenepal.com/storage/settings/June2021/ND1aTD9Qc3Ttt39iPmNj.PNG" alt="wefixit" />
              </a>
              <br></br>
                We Fix It Store Nepal
            </DrawerHeader>
            <DrawerBody>
              <ul style={{listStyle:'none'}}>
                <li>
                  <NavLink to="/" exact>Home</NavLink>
                </li>
                <li>
                  <a style={{cursor: 'pointer'}} onClick={e => goToHome(e, 'about')}>About</a>
                </li>
                <li>
                  <a style={{cursor: 'pointer'}}  onClick={e => goToHome(e, 'portfolio')}>Portfolio</a>
                </li>
                <li>
                  <a style={{cursor: 'pointer'}}  onClick={e => goToHome(e, 'drop-off')}>Drop Off</a>
                </li>
                <li>
                  <a style={{cursor: 'pointer'}} target='_blank'  href='https://repair.wefixitstorenepal.com/valuation'>Sell Phone</a>
                </li>
                <li>
                  <a style={{cursor: 'pointer'}} target='_blank'  href='https://repair.wefixitstorenepal.com/repair'>Repair Phone</a>
                </li>
                <li>
                  <a href="#contact">Contact</a>
                </li>
                <li>
                  <NavLink to="/blog">Blog</NavLink>
                </li>
              </ul>
              <Box mt="5" >
              <SearchInput>
                  <WeFixItInput
                    onChange={e=> setSearchQuery(e.target.value)}
                    value={searchQuery}
                    placeholder="Ticket ID or Phone"
                    type="text"
                    onKeyUp={e => (e.key === 'Enter' || e.keyCode === 13) && initSearch() }
                    icon={
                      // eslint-disable-next-line jsx-a11y/anchor-is-valid
                      <a onClick={initSearch} >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="24"
                          height="24"
                          viewBox="0 0 24 24"
                          fill="none"
                          stroke="currentColor"
                          strokeWidth="2"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          className="feather feather-search">
                            <circle cx="11" cy="11" r="8"></circle>
                            <line x1="21" y1="21" x2="16.65" y2="16.65"></line>
                        </svg>
                      </a>}
                    />
                </SearchInput>
              </Box>
            </DrawerBody>
          </DrawerContent>
        </Drawer>

      </header>
    </>
  );
}
